import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  HTTP_401_USER_PASSWORD_INCORRECT,
  HTTP_GENERAL_ERROR,
  HTTP_SET_IS_WATCHING_ERROR,
} from "@constants/network";
import {
  API_BASE_URL,
  API_ROUTE_LIVE_LOGOUT,
  API_ROUTE_LOGIN,
  API_ROUTE_LOGOUT,
  API_ROUTE_SESSION,
} from "@constants";
import { getCookie, setCookie } from "@utils/cookies";

interface AuthContextType {
  errorMessage: string;
  isAuthenticated: boolean;
  isLiveAuthenticated: boolean;
  isLoading: boolean;
  token: string;
  logIn: (
    username: string,
    password: string,
    rememberPassword?: boolean
  ) => Promise<void>;
  logout: () => Promise<void>;
  setIsWatching: () => Promise<void>;
  setErrorMessage: (message: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLiveAuthenticated, setIsLiveAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = getCookie("authToken");
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }

    const isLiveUser = getCookie("isLiveUser");
    if (isLiveUser === "true") {
      setIsLiveAuthenticated(true);
    }

    setIsLoading(false);
  }, []);

  const logIn = useCallback(
    async (username: string, password: string, rememberPassword?: boolean) => {
      try {
        setIsLoading(true);
        setErrorMessage("");
        const response = await fetch(API_BASE_URL + API_ROUTE_LOGIN, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
        });
        const { data } = await response.json();
        if (response.ok) {
          setToken(data.token);
          setIsAuthenticated(true);
          setIsLiveAuthenticated(data.isLiveUser);
          setCookie("authToken", data.token, {
            path: "/",
            maxAge: rememberPassword ? undefined : 86400,
          });
          setCookie("isLiveUser", data.isLiveUser, {
            path: "/",
            maxAge: rememberPassword ? undefined : 86400,
          });
        } else {
          if (response.status === 401) {
            setErrorMessage(HTTP_401_USER_PASSWORD_INCORRECT);
          } else {
            setErrorMessage(data.message || HTTP_GENERAL_ERROR);
          }
        }
      } catch (error) {
        setErrorMessage(HTTP_GENERAL_ERROR);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const logout = useCallback(async () => {
    setErrorMessage("");
    try {
      const logoutUrl = isLiveAuthenticated
        ? API_BASE_URL + API_ROUTE_LIVE_LOGOUT
        : API_BASE_URL + API_ROUTE_LOGOUT;
      const response = await fetch(logoutUrl, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsAuthenticated(false);
      setToken("");
      setIsLiveAuthenticated(false);
      document.cookie =
        "authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie =
        "isLiveUser=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }, [token, isLiveAuthenticated]);

  const setIsWatching = useCallback(async () => {
    try {
      if (isAuthenticated && !isLiveAuthenticated) {
        await fetch(`${API_BASE_URL}${API_ROUTE_SESSION}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ isWatching: true }),
        });
      }
    } catch (error) {
      setErrorMessage(HTTP_SET_IS_WATCHING_ERROR);
    }
  }, [token, isAuthenticated, isLiveAuthenticated]);

  const value = {
    errorMessage,
    isAuthenticated,
    isLiveAuthenticated,
    isLoading,
    token,
    logIn,
    logout,
    setIsWatching,
    setErrorMessage,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthStore = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthStore must be used within an AuthProvider");
  }
  return context;
};
